var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { left: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "disable-self-assign-btn",
                        attrs: {
                          disabled: _vm.disabled,
                          loading: _vm.loading,
                          "min-width": "30",
                          small: "",
                          title: _vm.title,
                        },
                        on: { click: _vm.toggle },
                      },
                      on
                    ),
                    [
                      _vm.isSelfAssignDisabled
                        ? _c("v-icon", { attrs: { small: "", color: "red" } }, [
                            _vm._v("mdi-clipboard-account"),
                          ])
                        : _c(
                            "v-icon",
                            { attrs: { small: "", color: "green" } },
                            [_vm._v("mdi-clipboard-account")]
                          ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v("\n            " + _vm._s(_vm.title) + "\n            "),
            _c("div", [
              _vm._v(
                "Durch einen Klick wird die selbst-Zuweisung " +
                  _vm._s(
                    _vm.isSelfAssignDisabled ? "zugelassen." : "unterbunden."
                  ) +
                  "."
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }