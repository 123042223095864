<template>
  <v-dialog
    v-model="isDialogOpen"
    content-class="v-application v-application--is-ltr"
  >
    <template v-slot:activator="{ on, attrs }">
      <a
        v-on="on"
        v-bind="attrs"
        style="color: inherit"
        class="edit-hours"
      >
        <v-text-field
          label="Stunden"
          v-model="appointment.quantity"
          required
          :rules="[beginEndTimeRule, decimalRule]"
          :hint="
            appointment.time ||
            defaultTimeSnippet ||
            beginEndTimeRule() ||
            decimalRule(appointment.quantity)
          "
          readonly
          append-icon="mdi-pencil"
          persistent-hint
          :class="{
            'hour-input': true,
            'error--text':
              decimalRule(appointment.quantity) !== true ||
              beginEndTimeRule() !== true,
            'stick-left': true,
          }"
        ></v-text-field>
      </a>
    </template>
    <v-card class="appointment-time-dialogue">
      <v-card-title
        >{{ nightshift ? "ND-" : "" }}Arbeitszeiten anpassen</v-card-title
      >
      <v-card-text>

        <v-container :class="`mx-0 px-0`">
        <v-row>
            <v-col cols="4" class="d-flex">
                <span>Mittagspause</span>
                <v-switch
                    :disabled="nightshift"
                    v-model="isBreak"
                    class="toggle-secondshift mt-0 pt-0 ml-2"
                ></v-switch>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-row class="time">
                    <v-col cols="12" md="6" lg="3">
                        <span class="text--secondary caption">Schichtbeginn</span>
                        <vc-date-picker
                            v-model="beginTime"
                            :minute-increment="15"
                            mode="time"
                            is-required
                            is24hr
                            class="firstshift-begin"
                        />
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                        <span class="text--secondary caption">Schichtende</span>
                        <vc-date-picker
                            v-model="endTime"
                            mode="time"
                            :validHours="{min: nightshift ? 0 : breakMinHour}"
                            :minute-increment="15"
                            is-required
                            is24hr
                            class="firstshift-end"
                        />
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12">
                <v-row class="time">
                    <v-col v-if="isBreak" cols="12" md="6" lg="3" role="paid-break">
                        <v-checkbox
                            v-model="isPaidBreak"
                            class="mt-0"
                            label="bezahlte Pause"
                            color="green"
                            dense
                            hide-details
                        />
                    </v-col>
                    <v-col v-if="isBreak" cols="12" md="6" lg="3">
                        <v-text-field
                            label="Dauer der Pause (in Stunden)"
                            v-model="breakDuration"
                            type="number"
                            min="1"
                            max="4"
                            dense
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col v-if="isBreak" cols="12" md="6" lg="3">
                        <v-checkbox
                            v-model="breakHasFixedTime"
                            class="mt-0"
                            label="festgelegte Pausenzeit?"
                            color="green"
                            dense
                            hide-details
                        />
                    </v-col>
                    <v-col v-if="isBreak && breakHasFixedTime" cols="12" md="6" lg="3">
                        <span class="text--secondary caption">Beginn der Pause*</span>
                        <vc-date-picker
                            v-model="breakStartTime"
                            mode="time"
                            :validHours="{min: breakMinHour, max: breakMaxHour}"
                            :minute-increment="15"
                            is-required
                            is24hr
                            class="firstshift-end"
                        />
                    </v-col>
                </v-row>
                <v-row class="mt-4">
                    <v-col cols="12" md="3">
                        <v-text-field
                            label="Berechnete Arbeitszeit"
                            v-model="quantity"
                            disabled
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                            label="AUSGABE"
                            v-model="time"
                            dense
                            class="working-hours"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>

      </v-card-text>
      <v-card-actions>
        <v-row justify="end">
          <v-col sm="4" md="2" cols="12">
            <v-btn @click="isDialogOpen = false" class="back" color="orange darken-1" text
              >Abbrechen</v-btn
            >
          </v-col>
          <v-col sm="4" md="2" cols="12">
            <v-btn color="green darken-1" text @click="save" class="save"
              >Speichern</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validators } from "../../validators.js";
import moment from 'moment'

const defaultBeginTime = '1900-01-01T08:00:00';
const defaultEndTime = '1900-01-01T18:00:00';
const defaultTimeSnippet = '08.00 - 18.00 Uhr';
const defaultNDTimeSnippet = 'ND 20.00 - 07.00 Uhr';
const NDdefaultBeginTime = '1900-01-01T20:00:00';
const NDdefaultEndTime = '1900-01-02T07:00:00';

const TIME_FORMAT = 'HH:mm';

function timeStringToMoment(timeString) {
    return moment(`1900-01-01T${timeString}:00`);
}

function momentToTimeString(m) {
    const s = m.format(TIME_FORMAT);
    return s == "00:00" ? "24:00" : s;
}

function calculatedTimePeriod(start, end) {
    return end.clone().subtract({
        hours: start.hours(),
        minutes: start.minutes(),
        seconds: start.seconds(),
        milliseconds: start.milliseconds(),
    });
}

export default {
    props: {
        /** Appointment data */
        value: {
        type: Object,
        required: true,
        },
        /** Default time snippet of the pharmacy for the corresponding day (`snippet<day>`) */
        defaultTimeSnippet: {
        type: String,
        required: false,
        },
    },
    data() {
        const { time_details } = this.value;

        return {
        momentBeginTime: this.value.begin ? timeStringToMoment(this.value.begin) : timeStringToMoment(defaultBeginTime),
        momentEndTime: this.value.end ? timeStringToMoment(this.value.end) : timeStringToMoment(defaultEndTime),
        time: this.value.time,
        quantity: this.value.quantity,
        isBreak: this.nightshift ? false : time_details.break_duration ? true : false,
        isPaidBreak: !!time_details.paid_break,
        breakDuration: time_details.break_duration ? time_details.break_duration / 60 : 0,
        breakHasFixedTime: time_details.break_has_fixed_time ? time_details.break_has_fixed_time : false,
        momentBreakStartTime: time_details.break_start_time ? timeStringToMoment(time_details.break_start_time) : null,

        isDialogOpen: false,
        decimalRule: validators.decimal,
        beginEndTimeRule: (_) =>
            !!this.appointment.begin ||
            !!this.appointment.end ||
            "Bitte Start- und Endzeit angeben",
        requiredRules: [validators.required],
        };
    },
    methods: {
        save() {
            this.$emit("input", this.appointment);
            this.isDialogOpen = false;
        },

        setDefaultTimes() {
            let beginTime = this.nightshift ? NDdefaultBeginTime : defaultBeginTime;
            let endTime = this.nightshift ? NDdefaultEndTime : defaultEndTime;
            this.momentBeginTime = moment(beginTime);
            this.momentEndTime = moment(endTime);
            this.time = this.nightshift ? defaultNDTimeSnippet : defaultTimeSnippet;
            this.quantity = this.nightshift ? 0 : 10;
        },

        setHoursQuantity() {
            if (!this.isDialogOpen) return
            const timeBeforeBreak = calculatedTimePeriod(this.momentBeginTime, this.momentEndTime);
            const hoursBeforeBreak = timeBeforeBreak.hours();
            const minutesBeforeBreak = timeBeforeBreak.minutes();
            const decimalTime = hoursBeforeBreak + (minutesBeforeBreak / 60);
            const unpaidBreakTime = this.isPaidBreak ? 0 : this.breakDuration;
            this.quantity = (decimalTime - unpaidBreakTime).toFixed(2); // Format to 2 decimal places
        },

        setTimeSnippet() {
            if (!this.isDialogOpen) return
            const beginTime = momentToTimeString(this.momentBeginTime).replace(':', '.');
            const endTime = momentToTimeString(this.momentEndTime).replace(':', '.');
            let prefix = this.nightshift ? 'ND ' : '';

            if (this.isBreak && this.breakDuration && !this.breakHasFixedTime) {
                const breakDurationString = this.isPaidBreak ? `(bezahlte Pause ${this.breakDuration} Std. )` : `(- ${this.breakDuration} Std.)`
                this.time = `${prefix}${beginTime} - ${endTime} Uhr  ${breakDurationString}`
            }
            else if (this.isBreak && this.breakDuration && this.breakHasFixedTime && this.breakStartTime) {
                const endTimeBeforeBreak = momentToTimeString(this.momentBreakStartTime).replace(':', '.');
                const beginTimeAfterBreak = momentToTimeString(this.momentBreakStartTime.clone().add(this.breakDuration, "hours")).replace(':', '.');
                const breakDurationString = this.isPaidBreak ? `(bezahlte Pause ${this.breakDuration} Std. )` : `(- ${this.breakDuration} Std.)`
                this.time = `${prefix}${beginTime} - ${endTimeBeforeBreak} Uhr  //  ${beginTimeAfterBreak} - ${endTime} Uhr ${breakDurationString}`
            }
            else {
                this.time = `${prefix}${beginTime} - ${endTime} Uhr`
            }
        },
    },

    computed: {
        appointment() {
            return { ...this.value, ...this.timesData }
        },
        nightshift() {
            return this.value.nightshift;
        },

        beginTime: {
            get() {
                return this.momentBeginTime.toISOString();
            },
            set(value) {
                this.momentBeginTime = moment(value);
                if (!this.nightshift && this.momentEndTime.isSameOrBefore(this.momentBeginTime)) {
                    // If the user selects a begin time that exceeds the end time, we force change the end time to be one-hour after the begin time
                    this.momentEndTime = this.momentBeginTime.clone().add(1, "hours")
                }
            }
        },

        endTime: {
            get() {
                return this.momentEndTime.toISOString();
            },
            set(value) {
                this.momentEndTime = moment(value);
            }
        },

        breakStartTime: {
            get() {
                if (!this.momentBreakStartTime) return
                return this.momentBreakStartTime.toISOString();
            },
            set(value) {
                this.momentBreakStartTime = moment(value);
            }
        },

        breakMinHour() {
            return this.momentBeginTime.clone().add(1, "hours").format("H")
        },

        breakMaxHour() {
            return this.momentEndTime.clone().subtract(1, "hours").format("H")
        },

        breakDurationInMinutes() {
            return Math.round(this.breakDuration * 60)
        },

        timesData() {
            return {
                'begin': this.momentBeginTime.format(TIME_FORMAT),
                'end': this.momentEndTime.format(TIME_FORMAT),
                'quantity': this.quantity,
                'time': this.time,
                'time_details': {
                    'paid_break': this.isPaidBreak,
                    'break_start_time': this.momentBreakStartTime?.format(TIME_FORMAT),
                    'break_has_fixed_time': this.breakHasFixedTime,
                    'break_duration': this.breakDurationInMinutes,
                }
            }
        },
    },

    watch: {
        isBreak(isBreak) {
            if (isBreak) this.breakDuration = 1
            else {
                this.breakDuration = 0;
                this.breakHasFixedTime = false
            }
            this.setDefaultTimes()
        },
        breakHasFixedTime(isFixedTime) {
            if (isFixedTime){
                const halfOfWorkingHours = Math.round(this.quantity / 2);
                this.momentBreakStartTime = this.momentEndTime.clone().subtract(halfOfWorkingHours, "hours")
            } else {
                this.momentBreakStartTime = null
            }
        },
        nightshift(hasNightShift) {
            if (hasNightShift && this.isBreak == true) this.isBreak = false;
            else this.setDefaultTimes()
        },
        "value.date"() {
            this.momentBeginTime = timeStringToMoment(this.value.begin);
            this.momentEndTime = timeStringToMoment(this.value.end);
            this.isBreak = false;
            this.isPaidBreak = false;
            this.time = this.value.time;
            this.quantity = this.value.quantity;
        }
    },
    created() {
        const keys = ['momentBeginTime', 'momentEndTime', 'isBreak', 'isPaidBreak', 'breakDuration', 'breakHasFixedTime', 'momentBreakStartTime'];
        keys.forEach(key => {
            this.$watch(key, this.setTimeSnippet);
            this.$watch(key, this.setHoursQuantity);
        });
    },
};
</script>

<style scope>
.time .vc-container .vc-time-picker .vc-date {
    display: none !important;
}

.hour-input .v-input__slot input {
  color: initial;
  cursor: pointer;
}

.hour-input .v-messages__message {
  color: rgba(0, 0, 0, 0.6);
}

.error--text .v-messages__message {
  color: inherit;
}

.hour-input div.v-input__slot .v-input__icon {
  cursor: pointer;
}

.hour-input .v-icon:before {
  color: initial;
}

</style>
