<template>
    <div class="vuetify-wrapper v-application">
        <template>
            <v-data-table
                :headers="headers"
                :items="sources"
                sort-by="calories"
                class="v-application--is-ltr"
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Quellen</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-dialog
                            v-model="isSourceDialogOpen"
                            max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2 create-source"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon color="black">mdi-plus</v-icon>
                                    Quelle
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form v-model="isValid">
                                    <v-card-title>
                                        <span class="text-h5">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" class="v-application--is-ltr">
                                                    <v-text-field
                                                        v-model="source.name"
                                                        :rules="[v => !!v]"
                                                        label="Quellen Name"
                                                        class="source-name"
                                                    />
                                                </v-col>
                                                <v-col cols="12" class="v-application--is-ltr">
                                                    <v-select
                                                        v-model="source.associatedDiscount"
                                                        :items="discounts"
                                                        label="Rabatt Name"
                                                        class="discount"
                                                    />
                                                </v-col>
                                                <v-col cols="12" class="v-application--is-ltr">
                                                    <v-checkbox v-model="source.manualAssignment" label="manuelle Zuordnung" hide-details />
                                                </v-col>
                                                <v-col cols="6" class="v-application--is-ltr">
                                                    <v-checkbox v-model="source.voucher" label="Gutschein per E-Mail senden?" />
                                                </v-col>
                                                <v-col v-if="source.voucher" cols="6" class="v-application--is-ltr">
                                                    <v-text-field
                                                        v-model="source.voucherValue"
                                                        :rules="[v => !!v]"
                                                        label="Gutscheincode"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="isSourceDialogOpen = false"
                                        >
                                            Abbrechen
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            class="save"
                                            text
                                            @click="save"
                                            :disabled="!isValid"
                                        >
                                            Speichern
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <DeleteAppointmentDialog
                            v-model="isDeleteDialogOpen"
                            message="Quelle Löschen?"
                            @confirm-deletion="remove"
                        />
                    </v-toolbar>
                </template>

                <template v-slot:item="{ item }">
                    <tr :data-id="item._id">
                        <td class="name">{{ item.name }}</td>
                        <td>{{ formatDate(item.timestamp) }}</td>
                        <td class="associated-discount">{{ getAssociatedDiscount(item.associated_discounts)}}</td>
                        <td>
                            <v-btn
                                class="btn-edit"
                                small
                                @click="onEdit(item)"
                            >
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                                class="btn-delete"
                                small
                                color="error"
                                @click="onDelete(item)"
                            >
                                <v-icon small>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </template>
    </div>
</template>

<script>
import axios from 'axios';
import DeleteAppointmentDialog from '../appointments/DeleteAppointmentDialog.vue';

const DEFAULT_SOURCE = {
    name: '',
    _id: null,
    associatedDiscount: null,
    manualAssignment: true,
    voucher: false,
    voucherValue: '',
}

export default {
    components: {
        DeleteAppointmentDialog
    },
    data() {
        return {
            headers: [
                { text: 'Name', value: 'name' },
                { text: 'Datum', value: 'timestamp' },
                { text: 'Rabatte', value: 'associated_discounts' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            sources: [],
            discounts: [],
            source: { ...DEFAULT_SOURCE },
            isValid: false,
            isEditing: false,
            isSourceDialogOpen: false,
            isDeleteDialogOpen: false,
        }
    },
    created() {
        this.getSources();
        this.getDiscounts();
    },
    methods: {
        async getSources() {
            const response = await axios('/api/sources/');
            this.sources = response.data
        },
        async save() {
            const payload = {
                "name": this.source.name,
                "timestamp": new Date().toISOString().slice(0, -1),
                "type": "admin",
                "associated_discounts": [this.source.associatedDiscount],
                "manual_assignment": this.source.manualAssignment,
                "voucher": this.source.voucher,
                ...(this.source.voucher && { "voucher_value": this.source.voucherValue })
            };
            try {
                const response = !this.isEditing
                    ? await axios.post('/api/sources/', payload)
                    : await axios.put(`/api/sources/${this.source._id}`, payload);

                this.getSources();
                this.isSourceDialogOpen = false
            } catch (error) {
                console.error(error)
            }
        },
        async getDiscounts() {
            try {
                const response = await axios.get("/api/discounts/");
                this.discounts = response.data.map(discount => (
                    {
                        ...discount,
                        text: discount.discountName,
                        value: discount._id
                    }
                ))
            } catch (error) {
                this.alertError();
            }
        },
        reset() {
            this.isEditing = false;
            this.source = { ...DEFAULT_SOURCE };
        },
        setSource(source) {
            this.source.name = source.name;
            this.source._id = source._id;
            this.source.associatedDiscount = source.associated_discounts[0];
            this.source.manualAssignment = source.manual_assignment;
            this.source.voucher = source.voucher;
            this.source.voucherValue = source.voucher_value;
        },
        onEdit(item) {
            this.isSourceDialogOpen = true;
            this.isEditing = true;
            this.setSource(item);
        },
        onDelete(item) {
            this.isDeleteDialogOpen = true;
            this.setSource(item);
        },
        async remove() {
            try {
                const response = await axios.delete(`/api/sources/${this.source._id}`);
                this.getSources();
                this.isDeleteDialogOpen = false
            } catch (error) {
                console.error(error)
            }
        },
        getAssociatedDiscount(associated_discounts) {
            return this.discounts.find(discount => discount._id === associated_discounts[0])?.discountName
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString('de-DE', {
                weekday: 'short',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })
        }
    },
    computed: {
        formTitle () {
            return this.isEditing === false ? 'Neue Quelle' : 'Quelle bearbeiten'
        },
    },
    watch: {
        isSourceDialogOpen(isOpen) {
            if (!isOpen) this.reset()
        },
        isDeleteDialogOpen(isOpen) {
            if (!isOpen) this.reset()
        },
    },
}
</script>

<style></style>