<template>
    <div class="vuetify-wrapper mx-auto text-center container">
        <v-btn
            @click="isDialogOpen = true"
            min-width="30"
            fab
            small
            class="pharmacists-requests"
        >
            <v-icon>mdi-account-multiple</v-icon>
            <span v-if="num_of_requests != 0" class="pharmacists-requests-count">
                {{ num_of_requests }}
            </span>
        </v-btn>
        <v-dialog v-model="isDialogOpen" scrollable content-class="v-application" max-width="90vw">
            <v-card :disabled="isLoading" :loading="isLoading" elevation="2">
                <v-card-title>Anfragen durch Freiberufler</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="h5">Freiberufler Können Anfragen senden, die hier gelistet
                            werden</v-col>
                        <v-col cols="12">
                            <div>
                                <b>Apotheke:</b>
                                <a :href="`/pharmacies/${pharmacy._id}`" target="_blank">
                                    {{ pharmacy.name }}
                                </a>
                            </div>
                            <v-row no-gutters>
                                <v-col cols="auto" class="font-weight-bold">Termin:</v-col>
                                <v-col class="ml-3">
                                    <div v-for="appointment in group">
                                        <span :class="appointment.commited && 'committed'">
                                            {{ formatDate(appointment.date, 'DD-MM-yyyy') }}
                                        </span>
                                        <span class="ml-2"> {{ appointment.time }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="auto" class="font-weight-bold">HBA zwingend notwendig: </v-col>
                                <v-col class="ml-3">{{ appointment.hba ? 'ja' : 'nein' }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="auto" class="font-weight-bold">WWS: </v-col>
                                <v-col class="ml-2">{{ pharmacy.erp }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="auto" class="font-weight-bold">Direkt Zusage: </v-col>
                                <v-col class="ml-2">{{ pharmacy.no_approval_required ? 'ja' : 'nein' }}</v-col>
                            </v-row>
                            <v-row v-if="assignedPharmacist?._id" no-gutters>
                                <v-col cols="auto" class="font-weight-bold">Freiberufler: </v-col>
                                <v-col class="ml-2">
                                    <a :href="`/pharmacists/${assignedPharmacist._id}`" target="_blank">
                                        {{ assignedPharmacist.firstname }} {{ assignedPharmacist.lastname }}
                                    </a>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Datum der Anfrage</th>
                                            <th>Entfernung/ Fahrkosten</th>
                                            <th>WWS | HBA</th>
                                            <th>war schonmal da?</th>
                                            <th>Anmerkungen /Kommentar</th>
                                            <th>Auswählen/Absage</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="{
                                            pharmacist,
                                            requestMessage,
                                            requestStatus,
                                            requestDate,
                                            knownPharmacist,
                                            costs,
                                            distance,
                                            is_capped,
                                            disableAcceptance,
                                            disableRejection,
                                        }, idx in requests" :data-id="pharmacist._id"
                                            :class="assignedPharmacist?._id === pharmacist._id ? 'already-allocated' : ''">
                                            <td>
                                                <div :class="getPharmacistAvailabilityStatus(pharmacist).classes">
                                                    <v-icon v-if="assignedPharmacist?._id === pharmacist._id"
                                                        class="mb-1 mr-1" small>
                                                        mdi-check-circle
                                                    </v-icon>
                                                    <a :href="`/pharmacists/${pharmacist._id}`" target="_blank">
                                                        {{ `${pharmacist.firstname} ${pharmacist.lastname}` }}
                                                    </a>
                                                </div>
                                            </td>
                                            <td>{{ requestDate }}</td>
                                            <td :style="{ color: is_capped ? 'red' : 'green' }">
                                                {{ distance }} km / {{ costs }} €
                                            </td>
                                            <td>
                                                <span
                                                    :style="{ color: parseInt(pharmacist.erp_expertise[pharmacy.erp]) >= 2 || pharmacist.erp_counts[pharmacy.erp] >= 2 ? 'green' : 'red' }">
                                                    {{ pharmacist.erp_expertise[pharmacy.erp] }}/3 ({{
                                                    pharmacist.erp_counts[pharmacy.erp] }})
                                                </span>
                                                |
                                                <span
                                                    :style="{ color: appointment.hba && !pharmacist.has_hba ? 'red' : 'green' }">
                                                    {{ pharmacist.has_hba ? 'HBA vorhanden' : 'Kein HBA' }}
                                                </span>
                                            </td>
                                            <td :style="{ color: knownPharmacist === 'Ja' ? 'green' : 'red' }">
                                                {{ knownPharmacist }}
                                            </td>
                                            <td class="message">{{ requestMessage }}</td>
                                            <td>
                                                <v-btn @click="accept(pharmacist)"
                                                    :disabled="disableAcceptance || !getPharmacistAvailabilityStatus(pharmacist).canAssign || appointment.wf_state == 'allocated'"
                                                    x-small color="success" class="accept">Auswählen</v-btn>

                                                <template>
                                                    <v-btn @click="requests[idx].isRejectionDialogOpen = true"
                                                        :disabled="disableRejection" x-small color="error"
                                                        class="reject">Absage</v-btn>
                                                    <RejectionDialog v-model="requests[idx].isRejectionDialogOpen"
                                                        @confirm-rejection="(comment) => reject(requests[idx].pharmacist, comment)" />
                                                </template>
                                                <v-btn @click="requests[idx].isThreadsDialogOpen = true" x-small dark
                                                    color="blue" class="open-threads">Starte eine
                                                    Konversation</v-btn>
                                                <PharmacistThreadsDialog v-model="requests[idx].isThreadsDialogOpen"
                                                    :pharmacistId="pharmacist._id" :pharmacyName="pharmacy.name"
                                                    :appointmentId="appointment._id"
                                                    :appointmentDate="formatDate(appointment.date, 'DD-MM-yyyy')" />
                                            </td>
                                            <td :style="{ color: requestStatus.toLowerCase() === 'accepted' ? 'green' : 'red' }"
                                                class="status">
                                                {{ getStatusName(requestStatus) }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import moment from 'moment';
import { mapMutations } from 'vuex';
import PharmacistThreadsDialog from "../pharmacist/threads/PharmacistThreadsDialog.vue";
import RejectionDialog from "./RejectionDialog.vue";

export default {
    props: ['appointment_data', 'pharmacy_data', 'pharmacist_data', 'known_pharmacists_data', 'num_of_requests'],
    components: {
        PharmacistThreadsDialog,
        RejectionDialog
    },
    data() {
        return {
            requests: [],
            isDialogOpen: false,
            isLoading: false,
            group: []
        }
    },
    computed: {
        appointment() {
            return this.jsonParser(this.appointment_data)
        },
        pharmacy() {
            return this.jsonParser(this.pharmacy_data)
        },
        assignedPharmacist() {
            if (!this.pharmacist_data) return null
            return this.jsonParser(this.pharmacist_data)
        },
        known_pharmacists() {
            const pharmacistsList = this.jsonParser(this.known_pharmacists_data);
            // Based on the parent component, the pharmacistsList maybe an array of pharmacists IDs, or an array of objects containing the pharmacists IDs
            return pharmacistsList.map(item => item?._id ? item?._id : item);
        },
    },
    methods: {
        async handleRequestsData() {
            const requests = Object.entries(this.appointment.pharmacist_requests); // [[pharmacistId, [message, name, status, date]], ...]
            this.isLoading = true;

            try {
                // Get the pharmacist object with all fields
                const pharmacistByIdResponse = await Promise.all(
                    requests.map(([pharmacistId]) => axios.get(`/api/contractors/${pharmacistId}`))
                );

                // Get the travel costs information for each pharmacist
                const appointmentCostsResponse = await Promise.all(
                    requests.map(([pharmacistId]) => this.getAppointmentCosts(pharmacistId))
                );

                this.requests = requests.map((pharmacist, idx) => {
                    const pharmacistId = pharmacist[0];
                    const pharmacistRequestInfo = pharmacist[1];

                    const knownPharmacist = this.known_pharmacists.some(id => id === pharmacistId)
                        ? 'Ja'
                        : 'Nein';

                    const travelcostsInfo = appointmentCostsResponse[idx].data.find(
                        ({ appointment_id }) => appointment_id === this.appointment._id
                    );
                    const requestDate = typeof pharmacistRequestInfo[3] === "object"
                        ? new Date(pharmacistRequestInfo[3]["$date"])
                        : pharmacistRequestInfo[3]

                    return {
                        pharmacist: pharmacistByIdResponse[idx].data,
                        requestMessage: pharmacistRequestInfo[0],
                        requestStatus: pharmacistRequestInfo[2],
                        requestDate: this.formatDate(requestDate, 'DD-MM-yyyy HH:mm'),
                        knownPharmacist,
                        costs: travelcostsInfo.costs,
                        distance: travelcostsInfo.distance,
                        is_capped: travelcostsInfo.is_capped,
                        disableAcceptance: pharmacistRequestInfo[2].toLowerCase() === 'accepted',
                        disableRejection: pharmacistRequestInfo[2].toLowerCase() === 'rejected',
                        isThreadsDialogOpen: false,
                        isRejectionDialogOpen: false,
                    }
                });
            } catch (err) {
                this.alertError(err.message)
            } finally {
                this.isLoading = false
            }
        },

        getAppointmentCosts(pharmacistId) {
            return axios.get(`/api/appointments/travelcosts`, {
                params: {
                    appointment_id: this.appointment._id,
                    pharmacist_id: pharmacistId
                }
            })
        },

        async getGroup() {
            if (this.appointment.group_id) {
                this.isLoading = true;
                try {
                    const response = await axios.get(`/api/appointments/fetch_one_appointment?appointment_id=${this.appointment._id}`);
                    this.group = response.data
                } catch (error) {
                    this.alertError(err.message)
                } finally {
                    this.isLoading = false;
                }
            } else {
                this.group = [{ ...this.appointment }]
            }
        },

        formatDate(date, format) {
            if (!date) return ''
            return moment.utc(date).local().format(format)
        },

        jsonParser(data) {
            return typeof data === 'string' ? JSON.parse(data) : data
        },

        getPharmacistAvailabilityStatus(pharmacist) {
            const isAllocated = this.isDateMatch(pharmacist.allocated_dates);
            if (isAllocated) return {
                classes: { ...this.getStatusCss('warning') },
                canAssign: false
            }

            const isUnavailable = this.isDateMatch(pharmacist.unavailable_dates);
            if (isUnavailable) return {
                classes: { ...this.getStatusCss('error') },
                canAssign: false
            }

            const isAvailable = pharmacist.available_dates.some(date => {
                return moment(date).isSame(moment(this.appointment.date))
            });
            if (isAvailable) return {
                classes: { ...this.getStatusCss('success') },
                canAssign: true
            }

            return { canAssign: true }
        },

        isDateMatch(dates) {
            const appointments = this.group.length ? this.group : [{ ...this.appointment }];
            return dates.some(date => {
                return appointments.some(appointment => {
                    return moment(date).isSame(moment(appointment.date))
                })
            });
        },

        getStatusCss(stateColor) {
            const cssClass = { 'pharmacist-name': true };
            return {
                ...cssClass,
                [stateColor]: true
            }
        },

        async reject({ _id }, rejectionComment) {
            console.log(`Pharmacist id - ${_id}, Appointment id - ${this.appointment._id}, Rejection comment - ${rejectionComment}`);
            // return;
            this.isLoading = true;
            try {
                const response = await axios.post(`/api/contractor/${_id}/reject_request/${this.appointment._id}`,{
                    'comment': rejectionComment
                });
                if (response.status !== 200) throw new Error('Ein Fehler ist aufgetreten.');
                this.requests.forEach(request => {
                    if (request.pharmacist._id === _id) {
                        request.requestStatus = 'rejected';
                        request.disableRejection = true;
                    }
                });
            } catch (error) {
                this.alertError(error.message)
            } finally {
                this.isLoading = false;
            }
        },

            async accept(pharmacist) {
                this.requests.forEach(request => {
                    request.disableAcceptance = true;
                    request.disableRejection = true;
                })
                this.$emit('selected-user', pharmacist);
                this.isDialogOpen = false;
            },

        getStatusName(status) {
            switch (status.toLowerCase()) {
                case 'rejected':
                    return 'Abgesagt'
                case 'accepted':
                    return 'Zugesagt'
                case 'cancelled':
                    return 'Storniert'
                default:
                    return ''
            }
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
    watch: {
        isDialogOpen(isOpen) {
            if (isOpen && !this.requests.length) {
                this.getGroup();
                this.handleRequestsData();
            }
        }
    },
}
</script>

<style scoped>
    td, th {
        text-align: center;
    }
    .container {
        width: 100%;
    }
    .pharmacist-name {
        color: #fff;
        border-radius: 7px;
        padding: 2px 10px;
    }
    .pharmacist-name a {
        color: #fff;
    }
    .already-allocated, .already-allocated:hover {
        background: #e0ffe0 !important;
    }
    .pharmacists-requests-count {
        width: 1.3em;
        min-width: min-content;
        height: 1.3em;
        background: #da1818;
        color: white;
        position: absolute;
        right: 0;
        top: 1.6em;
        border-radius: 50%;
        padding-top: 0.1em;
    }
</style>