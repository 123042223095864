<template>
    <div :id="tab">
        <v-card :disabled="isLoading" class="v-application appointments-overview">
            <v-card-title>
                <v-text-field
                    v-model="search"
                    v-if="!isPharmacy"
                    append-icon="mdi-magnify"
                    placeholder="Suche nach Datum, Name der Apotheke"
                    class="search-appointments"
                    single-line
                    hide-details
                    clearable
                    autofocus
                ></v-text-field>
            </v-card-title>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="tableRows"
                class="v-application--is-ltr"
                mobile-breakpoint="670"
                :items-per-page="25"
                :page.sync="pageNumber"
                :options.sync="tableOptions"
                no-data-text="Keine Suchergebnisse"
                @update:options="onOptionsChange"
                @toggle-select-all="onSelectAll"
                :server-items-length="appointmentsCount"
                :loading="isLoading"
                loading-text="Wird geladen..."
                show-expand
                :expanded.sync="expanded"
                item-key="_id"
                hide-default-footer
            >
                <template v-slot:item="{ item, isSelected, expand, isExpanded, }">
                    <TableRow
                        :appointment="item"
                        :key="item._id"
                        @select="isSelected => select(isSelected, item)"
                        @select-group="isSelected => selectGroup(isSelected, item)"
                        @expand="expand"
                        :isSelected="isSelected"
                        :isExpanded="isExpanded"
                        :isPharmacy="isPharmacy"
                        :wfState="tab"
                        :groupAppointments="groups[item.group_id]"
                        @update-group-travelcosts="({appointmentId, travelcosts}) => updateGroupTravelcosts(item.group_id, appointmentId, travelcosts)"
                        @update-appointments="updateAppointments"
                    />
                </template>
                <template v-slot:expanded-item="{ item }">
                    <TableRow
                        v-for="appointment in groups[item['group_id']]"
                        :appointment="appointment"
                        @select="isSelected => select(isSelected, appointment)"
                        :isSelected="selected.some(apt => apt._id === appointment._id)"
                        :isExpanded="expanded.some(apt => apt._id === item._id)"
                        :isPharmacy="isPharmacy"
                        :wfState="tab"
                    />
                </template>
            </v-data-table>
            <v-card-text class="footer text-center pt-2">
                <BatchUpdateAppointments
                    v-if="selected.length"
                    :selectedCount="selected.length"
                    :tab="tab"
                    :pharmacy_id="pharmacy_id"
                    @grouping="isGroupDialogOpen = true"
                    @confirming="isConfirmDialogOpen = true"
                    @batch-update="batchUpdateAppointments"
                    :isUngroupingEnabled="isUngroupingEnabled"
                    :selectedAppointmentsIds="selectedAppointmentsIds"
                />

                <v-row>
                    <v-col class="d-flex align-center justify-center">
                        Zeilen pro Seite:
                        <v-select
                            v-model="tableOptions.itemsPerPage"
                            :items="[5, 10, 25, 50, 100]"
                            placeholder="Zeilen pro Seite"
                            class="pt-0 ml-2 mt-0 mr-5"
                            hide-details
                        ></v-select>
                        <v-pagination
                            v-model="tableOptions.page"
                            :length="Math.ceil(appointmentsCount / tableOptions.itemsPerPage)"
                            :total-visible="11"
                        ></v-pagination>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <AddToGroup
            v-if="isGroupDialogOpen"
            @add-group="addToGroup"
            v-model="isGroupDialogOpen"
            :groupFirstAppointments="groupFirstAppointments"
        />
        <ConfirmationDialog
            v-model="isConfirmDialogOpen"
            :message="`${selectedAppointmentsIds.length} Termine ausgewählt`"
            @save="removeFromGroup"
        />
    </div>
</template>

<script>
    import Vue from 'vue';
    import axios from 'axios';
    import _ from 'lodash';
    import TableRow from './TableRow.vue';
    import { mapMutations } from 'vuex';
    import AddToGroup from './AddToGroup.vue';
    import ConfirmationDialog from './ConfirmationDialog.vue';
    import BatchUpdateAppointments from './BatchUpdateAppointments.vue';

    export default {
        components: {
            TableRow,
            AddToGroup,
            ConfirmationDialog,
            BatchUpdateAppointments
        },
        props: {
            tab: String,
            totalAppointmentsCount: Number,
            sort: Object,
            filters: Object,
            pharmacy_id: String
        },
        data: () => ({
            search: '',
            pageNumber: 1,
            expanded: [],
            tableOptions: {},
            selected: [],
            headers: [
                { text: '', value: 'data-table-expand', class: 'header-cell' },
                { text: 'Datum', value: 'date', class: 'header-cell' },
                { text: 'bis Datum', value: 'group_enddate', class: 'header-cell' },
                { text: 'Tage', value: 'group_size', class: 'header-cell' },
                { text: 'Apotheke', value: 'pharmacy', class: 'header-cell' },
                { text: 'Zeit', value: 'time', class: 'header-cell' },
                { text: 'Freiberufler', value: 'pharmacist', class: 'header-cell' },
                { text: 'Anfahrt', value: 'travelcosts', class: 'header-cell' },
                { text: 'Betrag', value: '', class: 'header-cell' },
                { text: '', value: 'buttons', class: 'header-cell' },
            ],
            groups: {},
            appointments: [],
            appointmentsCount: 0,
            isLoading: false,
            isGroupDialogOpen: false,
            isConfirmDialogOpen: false
        }),
        computed: {
            tableRows() {
                return this.mapAppointments();
            },
            isFilterChanged() {
                return Object.values(this.filters).some(filter => filter != null);
            },
            isPharmacy() {
                return Boolean(this.pharmacy_id)
            },
            selectedAppointmentsIds() {
                return this.selected.map(appointment => appointment._id)
            },
            isUngroupingEnabled() {
                return this.selected.every(appointment => appointment.group_id)
            },
            groupFirstAppointments() {
                return this.tableRows.filter(appointment => (appointment.group_first))
            }
        },
        methods: {
            mapAppointments() {
                return this.appointments.map(item => {
                    if (Array.isArray(item)) { // If the item is an array, it means it's a group appointments
                        this.$set(this.groups, item[0].group_id, item.slice(1));
                        return item[0];
                    }
                    return item;
                });
            },
            async onOptionsChange({ page, itemsPerPage, sortBy, sortDesc }) {
                if (this.isFilterChanged) this.fetchFilteredAppointments(page, itemsPerPage);
                else if (this.search) this.searchAppointments(page, itemsPerPage)
                else this.fetchAppointments(page, itemsPerPage, sortBy[0], sortDesc[0] ? -1 : 1);

                if(!this.isPharmacy) this.$nextTick(() => {
                    window.scrollTo(0, 0);
                })
            },

            async fetchAppointments(page = 1, pageSize = 25, sort_by = 'date', sort_asc = 1) {
                try {
                    this.isLoading = true;
                    const response = await axios.get('/api/appointments/overview', {
                        params: {
                            page,
                            page_size: pageSize,
                            sort_by,
                            sort_asc,
                            status: this.tab,
                            pharmacy_id: this.pharmacy_id
                        },
                    });
                    this.appointments = response.data.appointments;
                    this.appointmentsCount = this.totalAppointmentsCount;
                    this.tableOptions.page = page;
                    this.tableOptions.itemsPerPage = pageSize;
                } catch (error) {
                    this.alertError();
                } finally {
                    this.isLoading = false;
                }
            },

            searchAppointments: _.debounce(async function (page = 1, pageSize = 25) {
                if (!this.search) return;
                try {
                    this.isLoading = true;
                    const response = await axios.get(`/api/appointments/searchoverview`, {
                        params: {
                            query: this.search,
                            page: page - 1, // solr pagination is zero-indexed
                            page_size: pageSize,
                            wf_state: this.tab == 'completed_no_apo_invoice' ? 'allocated' : this.tab,
                        },
                    });
                    if (response.data.status == 500) throw new Error();

                    const data = response.data.results;
                    const appointmentsSearchResults = [];

                    for (const item of data) {
                        const response = await axios.get(
                            `/api/appointments/fetch_one_appointment?appointment_id=${item._id}`
                        );
                        appointmentsSearchResults.push(response.data);
                    }
                    this.appointments = appointmentsSearchResults;
                    this.appointmentsCount = response.data.total_results;
                    this.tableOptions.page = page;
                    this.tableOptions.itemsPerPage = pageSize;
                } catch (error) {
                    this.alertError();
                } finally {
                    this.isLoading = false;
                }
            }, 200),

            async fetchFilteredAppointments(page = 1, pageSize = 25) {
                const params = {
                    page,
                    page_size: pageSize,
                    wf_state: this.tab,
                    ...this.filters
                };
                try {
                    this.isLoading = true;
                    const response = await axios.get(`/api/appointments/filtered`, { params });
                    this.appointments = response.data.appointments;
                    this.appointmentsCount = response.data.total_count;
                    this.tableOptions.page = page;
                    this.tableOptions.itemsPerPage = pageSize;
                } catch (error) {
                    this.alertError();
                } finally {
                    this.isLoading = false;
                }
            },

            async batchUpdateAppointments(path) {
                try {
                    const response = await axios.post(`/pharmacies/${this.pharmacy_id}/${path}`, {
                        'aids': this.selectedAppointmentsIds
                    });
                    this.selected = [];
                    this.alertSuccess(response.data.message);
                    this.$emit('get-counts');
                } catch (error) {
                    this.alertError();
                }
            },

            select(isSelected, appointment) {
                isSelected
                    ? this.selected.push(appointment)
                    : this.selected = this.selected.filter(apt => apt._id !== appointment._id)
            },
            selectGroup(isSelected, apt) {
                this.select(isSelected, apt);
                this.groups[apt['group_id']].forEach(apt => this.select(isSelected, apt))
            },
            /**
             * fires when the select-all checkbox is clicked
             * @param value: a Boolean that determines whether the checkbox is checked or not
             */
            onSelectAll({ value }) {
                this.selected = []; // reset selected and expanded rows
                this.expanded = [];
                if (!value) return // return if it's false (unchecked)

                // set the expanded and selected rows manually
                this.expanded = this.groupFirstAppointments;
                this.$nextTick(() => {
                    this.selected.push(...this.tableRows)
                    this.groupFirstAppointments.forEach(apt => {
                        this.selected.push(...this.groups[apt.group_id])
                    })
                })
            },
            updateGroupTravelcosts(groupId, appointmentId, travelcosts) {
                this.groups[groupId].forEach(appointment => {
                    if (appointment._id == appointmentId) {
                        appointment.travelcosts = travelcosts;
                    }
                })
            },
            /**
             * Updates already fetched and possibly currently displayed appointments
             * with the updated values in `appointments`.
             * Intended to be used with buttons/user interactions that change an appointments state
             * and return the updated appointments, so that we can update these appointments
             * without having to fetch the entire list again, making the user experience smoother.
             */
            updateAppointments(appointments) {
                const replaceAppointment = (appointmentList, appointmentToUpdate) => {
                    const i = appointmentList.findIndex(a => a._id == appointmentToUpdate._id);
                    if (i != -1) {
                        // merge old and new appointment to keep additional fields like `pharmacy`
                        Vue.set(appointmentList, i, { ...appointmentList[i], ...appointmentToUpdate });
                    }
                }
                const replaceAppointmentConsideringGroups = (appointmentList, appointmentToUpdate) => {
                    replaceAppointment(appointmentList, appointmentToUpdate);
                    // handle appointment-groups, which are stored as arrays
                    appointmentList
                        .filter(Array.isArray)
                        .forEach((appois) => replaceAppointment(appois, appointmentToUpdate));
                }

                appointments.forEach(appointment => {
                    // appointment could be in any/all of these arrays:
                    replaceAppointmentConsideringGroups(this.appointments, appointment);
                });
            },
            async addToGroup({ mode=0, groupId }) {
                try {
                    const response = await axios.post(`/pharmacies/${this.pharmacy_id}/add_appointment_to_group`, {
                        add_mode: mode,
                        appointment_ids: this.selectedAppointmentsIds,
                        group_id: groupId
                    });
                    if (response.data.status == 400) throw new Error(response.data.message)
                    this.fetchAppointments();
                    this.selected = [];
                    this.alertSuccess('Gruppe wurde erstellt.')

                } catch (error) {
                    this.alertError(error)
                }
            },
            async removeFromGroup() {
                try {
                    const response = await axios.post(`/pharmacies/${this.pharmacy_id}/remove_appointment_from_group`, {
                        appointment_ids: this.selectedAppointmentsIds,
                    });
                    if (response.data.status === 400) throw new Error(response.data.error)
                    this.fetchAppointments();
                    this.selected = [];
                    this.alertSuccess('Termin(e) erfolgreich aus Gruppe entfernt.')

                } catch (error) {
                    this.alertError(error)
                }
            },
            ...mapMutations({
                alertError: 'alert/error',
                alertSuccess: 'alert/success',
            })
        },
        watch: {
            search(text) {
                text?.trim().length
                    ? this.searchAppointments()
                    : this.fetchAppointments()
            },
            filters: {
                handler() {
                    this.isFilterChanged
                        ? this.fetchFilteredAppointments()
                        : this.fetchAppointments()
                },
                deep: true
            },
            sort: {
                handler({ sortBy, sortAsc }) {
                    const { page, itemsPerPage } = this.tableOptions;
                    !sortBy
                        ? this.fetchAppointments(page, itemsPerPage)
                        : this.fetchAppointments(page, itemsPerPage, sortBy, sortAsc);
                },
                deep: true
            },
            totalAppointmentsCount() {
                this.fetchAppointments(); // get appointments if the total count is changed
            }
        },
        created() {
            if (this.isPharmacy) {
                this.headers.splice(0, 0, {
                    text: '',
                    value: 'data-table-select',
                    class: 'header-cell'
                })
            }
        },
    };
</script>

<style>
    .appointments-overview .footer {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        background: #f0fcff;
        border-top: thin solid rgb(0 0 0 / 16%);
        padding-inline: 11px;
    }
    .footer .v-select {
        max-width: 50px;
    }
    .batch-update {
        background-color: #d4fce5;
    }
    .price-actions {
        width: 100%;
    }
    .header-cell {
        font-size: 0.8rem !important;
    }
    .header-cell span {
        display: block;
    }
    .mdi-checkbox-marked {
        color: #1976d2 !important;
        caret-color: #1976d2 !important;
    }
</style>
