<template>
    <span>
        <v-btn
            @click.stop="toggleDialog"
            :loading="loading"
            :class="{ 'btn-primary': true, 'bootstrap-button': icon }"
            :disabled="appointmentIds.length == 0"
            :small="icon"
            :icon="icon"
            :outlined="icon"
            dense
            rounded
            small
        >
            <v-icon v-if="icon" small>{{ icon }}</v-icon>
            <span v-else>
                Update {{billingString}}
            </span>
        </v-btn>
        <v-dialog content-class="v-application v-application--is-ltr vuetify-wrapper" v-model="dialog" width="auto">
            <v-card>
                <v-card-title class="text-h5">
                    Update {{billingString}}
                </v-card-title>
                <v-card-text v-if="loading">
                    Lade Preise...
                </v-card-text>
                <v-card-text v-if="rateTypes.size > 1">
                    <div class="alert alert-danger" role="alert">
                        <strong>Warnung!</strong> Bitte wählen Sie nur Termine aus, die entweder nur nach Stunden
                        bzw.
                        nur pauschal abgerechnet werden.
                    </div>
                </v-card-text>
                <v-card-text v-else>
                    <p class="description">
                        Hier kann ausgewählt werden, ob dem Freiberufler/dem Apothekeninhaber die hier angegebenen
                        Preise angezeigt werden sollen oder die am Termin gespeicherten 2023-Pricing Preise. Sollte
                        letzteres ausgewählt sein, diese aber noch nicht existieren, wird dem Freiberufler angezeigt,
                        dass die Preise bei FPP angefragt werden können.
                    </p>
                    <div class="wrapper">
                        <div>
                            <h4>Apotheke</h4>
                            <v-switch label="2023-Preisvorschläge verwenden" v-model="apo2023Pricing" :disabled="disable2023Pricing" />
                            <v-text-field :label="billingString" :hint="apoRatesHint"
                                :persistent-hint="availableApoRates.size > 1" v-model="apoRate" type="number"
                                :rules="[validateCurrency]"
                                v-if="!apo2023Pricing"/>
                        </div>
                        <div>
                            <h4>Freiberufler</h4>
                            <v-switch label="2023-Preisvorschläge verwenden" v-model="ma2023Pricing" :disabled="disable2023Pricing"/>

                            <v-text-field :label="billingString" :hint="maRatesHint"
                                :persistent-hint="availableMaRates.size > 1" v-model="maRate" type="number"
                                :rules="[validateCurrencyFb]" v-if="!ma2023Pricing"/>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="abort" text @click="cancelDialog">
                        Abbrechen
                    </v-btn>
                    <v-btn color="submit" text @click="submitDialog" :loading="loading" :disabled="!valid">
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </span>
</template>

<script>

import axios from 'axios';
import { mapMutations } from 'vuex';

export default {
    name: 'BatchUpdatePrices',
    components: {},
    props: {
        appointmentIds: Array,
        icon: String,
    },
    data: function () {
        return {
            loading: false,
            dialog: false,
            rates: [],
            apoRate: null,
            apo2023Pricing: false,
            maRate: null,
            ma2023Pricing: false,
        };
    },
    computed: {
        rateTypes() {
            return new Set(this.rates.map(a => a.type))
        },
        availableApoRates() {
            return new Set(this.rates.map(a => a.hourly_apo_rate ?? a.flatrate_apo))
        },
        availableMaRates() {
            return new Set(this.rates.map(a => a.hourly_ma_rate ?? a.flatrate_ma))
        },
        apoRatesHint() {
            if (this.availableApoRates.size <= 1) return '';
            return 'Bisherige Werte: ' + this.rates.map(a => a.hourly_apo_rate + ' €').join(', ');
        },
        maRatesHint() {
            if (this.availableMaRates.size <= 1) return '';
            return 'Bisherige Werte: ' + this.rates.map(a => a.hourly_ma_rate + ' €').join(', ');
        },
        valid() {
            return [
                this.rates.length > 0,
                this.rateTypes.size == 1,
                this.ma2023Pricing || this.validateCurrencyFb(this.maRate)===true,
                this.apo2023Pricing || this.validateCurrency(this.apoRate)===true,
            ].every(Boolean)
        },
        billingString() {
            if (!this.rates || this.rates.length == 0) {
                return 'Stundenhonorar/Pauschale'
            }
            if (this.rates[0].type.toLowerCase() == 'hours')
                return 'Stundenhonorar';
            return 'Pauschale';
        },
        hasPharmacistAssigned() {
            return this.rates?.some(r => r.has_pharmacist_assigned);
        },
        disable2023Pricing() {
            return this.hasPharmacistAssigned;
        }
    },
    watch: {
        async appointmentIds(next) {
           await this.loadDataForAppointmentIds(next);
        },
    },
    methods: {
        async toggleDialog() {
            this.dialog = !this.dialog;
            await this.loadDataForAppointmentIds(this.appointmentIds);
        },
        cancelDialog() {
            this.dialog = false;
        },
        async submitDialog() {
            this.loading = true;
            try {
                const updatedAppointments = await Promise.all(
                    this.rates.map(rate => (
                        axios.post(
                            `/api/appointments/${rate.id}/rates`,
                            {
                                apo_rate: this.apo2023Pricing ? null : this.apoRate,
                                ma_rate: this.ma2023Pricing ? null : this.maRate,
                            }
                        ).then(r => r.data)
                    ))
                );
                this.$emit("save", updatedAppointments)
            } catch (error) {
                this.alertError()
            }
            this.dialog = false;
            this.loading = false;
        },
        validateCurrency(value) {
            if (!Number.isFinite(parseFloat(value)))
                return "Bitte eine Zahl eingeben"

            if (value <= 0)
                return "Bitte eine Zahl größer 0 eingeben"
            return true
        },
        validateCurrencyFb(value){
            if (!Number.isFinite(parseFloat(value)))
                return "Bitte eine Zahl eingeben"

            if (this.hasPharmacistAssigned) {
                if (value < 0)
                    return "Bitte eine positive Zahl eingeben"
                return true
            }

            if (value <= 0)
                return "Bitte eine Zahl größer 0 eingeben"
            return true
        },
        async loadDataForAppointmentIds(appointmentIds) {
            if (!this.dialog) {
                // don't load while data is not displayed anyways
                return;
            }

            this.loading = true;
            try {
                this.rates = await Promise.all(
                    appointmentIds.map(appointmentId => (
                        axios
                            .get(`/api/appointments/${appointmentId}/rates`)
                            .then(r => ({ id: appointmentId, ...r.data }))
                    ))
                );

                this.apoRate = this.availableApoRates.size === 1
                    ? this.availableApoRates.keys().next().value
                    : null;

                this.apo2023Pricing = !this.disable2023Pricing && !this.apoRate;


                this.maRate = this.availableMaRates.size === 1
                    ? this.availableMaRates.keys().next().value
                    : null;

                this.ma2023Pricing = !this.disable2023Pricing && !this.maRate;

            } catch (error) {
                this.alertError()
            }
            this.loading = false;
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
};
</script>

<style scoped>
.btn-primary {
    color: #fff !important;
    background-color: #337ab7 !important;
    border-color: #2e6da4 !important;
}

.black--text {
    color: black;
}

.description {
    max-width: 600px;
    margin: 0 auto;
}

.wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.wrapper > div {
    margin: 0 1em;
}

.content {
    width: 500px;
}

.abort {
    color: rgba(0, 0, 0, .5) !important;
}

.submit {
    color: #337ab7 !important;
}

.bootstrap-button {
    /** Make it look the same as the bootstrap buttons */
    border-radius: 3px;
    border-color: #ccc;
    width: 34px !important;
    height: 30px !important;
}
</style>
