import { render, staticRenderFns } from "./PharmacistSearchDropdown.vue?vue&type=template&id=a56fae10&scoped=true&"
import script from "./PharmacistSearchDropdown.vue?vue&type=script&lang=js&"
export * from "./PharmacistSearchDropdown.vue?vue&type=script&lang=js&"
import style0 from "./PharmacistSearchDropdown.vue?vue&type=style&index=0&id=a56fae10&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a56fae10",
  null
  
)

export default component.exports