function isURL(str) {
    let url

    if (!str) return true

    try {
        url = new URL(str)
    } catch (_) {
        return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
}

export const validators = {
    required: (v) => !!v || 'Dieses Feld ist ein Pflichtfeld',
    length5: (v) => v.length <= 5 || 'Die Eingabe darf nicht länger als 5 Zeichen sein',
    length10: (v) => v.length <= 10 || 'Die Eingabe darf nicht länger als 10 Zeichen sein',
    length30: (v) => v.length <= 30 || 'Die Eingabe darf nicht länger als 30 Zeichen sein',
    length100: (v) => v.length <= 100 || 'Die Eingabe darf nicht länger als 100 Zeichen sein',
    email: (v) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    emailList: (v) =>
        /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/.test(v) ||
        'Bitte geben Sie eine Liste gültiger E-Mail-Adressen durch Komma getrennt ein',
    decimal: (v) =>
        /^(\d+(?:[\.\,]\d{1,2})?)$/.test(v) || 'Bitte geben Sie eine gültige Dezimalzahl ein',
    isURL: (v) => isURL(v) || 'Bitte geben Sie eine gültige URL ein.',
    validPassword: (v) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(v) ||
        'Passwort muss min. 8 Zeichen lang sein, einen Großbuchstaben sowie eine Ziffer enthalten',
    pwMinLength8: (v) => (v && v.length >= 8) || 'Das Passwort muss mindestens 8 Zeichen lang sein',
    pwMinOneNumber: (v) => /[0-9]/.test(v) || 'Das Passwort muss mindestens eine Ziffer enthalten',
    pwMinOneUpper: (v) =>
        /[A-Z]/.test(v) || 'Das Passwort muss mindestens einen Großbuchstaben enthalten',
    pwMinOneLower: (v) =>
        /[a-z]/.test(v) || 'Das Passwort muss mindestens einen Kleinbuchstaben enthalten',
    greaterThanOrEqual70: (v) => v >= 70 || 'mind. 70€'
}
