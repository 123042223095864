var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.tab } },
    [
      _c(
        "v-card",
        {
          staticClass: "v-application appointments-overview",
          attrs: { disabled: _vm.isLoading },
        },
        [
          _c(
            "v-card-title",
            [
              !_vm.isPharmacy
                ? _c("v-text-field", {
                    staticClass: "search-appointments",
                    attrs: {
                      "append-icon": "mdi-magnify",
                      placeholder: "Suche nach Datum, Name der Apotheke",
                      "single-line": "",
                      "hide-details": "",
                      clearable: "",
                      autofocus: "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            staticClass: "v-application--is-ltr",
            attrs: {
              headers: _vm.headers,
              items: _vm.tableRows,
              "mobile-breakpoint": "670",
              "items-per-page": 25,
              page: _vm.pageNumber,
              options: _vm.tableOptions,
              "no-data-text": "Keine Suchergebnisse",
              "server-items-length": _vm.appointmentsCount,
              loading: _vm.isLoading,
              "loading-text": "Wird geladen...",
              "show-expand": "",
              expanded: _vm.expanded,
              "item-key": "_id",
              "hide-default-footer": "",
            },
            on: {
              "update:page": function ($event) {
                _vm.pageNumber = $event
              },
              "update:options": [
                function ($event) {
                  _vm.tableOptions = $event
                },
                _vm.onOptionsChange,
              ],
              "toggle-select-all": _vm.onSelectAll,
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item, isSelected, expand, isExpanded }) {
                  return [
                    _c("TableRow", {
                      key: item._id,
                      attrs: {
                        appointment: item,
                        isSelected: isSelected,
                        isExpanded: isExpanded,
                        isPharmacy: _vm.isPharmacy,
                        wfState: _vm.tab,
                        groupAppointments: _vm.groups[item.group_id],
                      },
                      on: {
                        select: (isSelected) => _vm.select(isSelected, item),
                        "select-group": (isSelected) =>
                          _vm.selectGroup(isSelected, item),
                        expand: expand,
                        "update-group-travelcosts": ({
                          appointmentId,
                          travelcosts,
                        }) =>
                          _vm.updateGroupTravelcosts(
                            item.group_id,
                            appointmentId,
                            travelcosts
                          ),
                        "update-appointments": _vm.updateAppointments,
                      },
                    }),
                  ]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ item }) {
                  return _vm._l(
                    _vm.groups[item["group_id"]],
                    function (appointment) {
                      return _c("TableRow", {
                        attrs: {
                          appointment: appointment,
                          isSelected: _vm.selected.some(
                            (apt) => apt._id === appointment._id
                          ),
                          isExpanded: _vm.expanded.some(
                            (apt) => apt._id === item._id
                          ),
                          isPharmacy: _vm.isPharmacy,
                          wfState: _vm.tab,
                        },
                        on: {
                          select: (isSelected) =>
                            _vm.select(isSelected, appointment),
                        },
                      })
                    }
                  )
                },
              },
            ]),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "footer text-center pt-2" },
            [
              _vm.selected.length
                ? _c("BatchUpdateAppointments", {
                    attrs: {
                      selectedCount: _vm.selected.length,
                      tab: _vm.tab,
                      pharmacy_id: _vm.pharmacy_id,
                      isUngroupingEnabled: _vm.isUngroupingEnabled,
                      selectedAppointmentsIds: _vm.selectedAppointmentsIds,
                    },
                    on: {
                      grouping: function ($event) {
                        _vm.isGroupDialogOpen = true
                      },
                      confirming: function ($event) {
                        _vm.isConfirmDialogOpen = true
                      },
                      "batch-update": _vm.batchUpdateAppointments,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "d-flex align-center justify-center" },
                    [
                      _vm._v(
                        "\n                    Zeilen pro Seite:\n                    "
                      ),
                      _c("v-select", {
                        staticClass: "pt-0 ml-2 mt-0 mr-5",
                        attrs: {
                          items: [5, 10, 25, 50, 100],
                          placeholder: "Zeilen pro Seite",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.tableOptions.itemsPerPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableOptions, "itemsPerPage", $$v)
                          },
                          expression: "tableOptions.itemsPerPage",
                        },
                      }),
                      _vm._v(" "),
                      _c("v-pagination", {
                        attrs: {
                          length: Math.ceil(
                            _vm.appointmentsCount /
                              _vm.tableOptions.itemsPerPage
                          ),
                          "total-visible": 11,
                        },
                        model: {
                          value: _vm.tableOptions.page,
                          callback: function ($$v) {
                            _vm.$set(_vm.tableOptions, "page", $$v)
                          },
                          expression: "tableOptions.page",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isGroupDialogOpen
        ? _c("AddToGroup", {
            attrs: { groupFirstAppointments: _vm.groupFirstAppointments },
            on: { "add-group": _vm.addToGroup },
            model: {
              value: _vm.isGroupDialogOpen,
              callback: function ($$v) {
                _vm.isGroupDialogOpen = $$v
              },
              expression: "isGroupDialogOpen",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ConfirmationDialog", {
        attrs: {
          message: `${_vm.selectedAppointmentsIds.length} Termine ausgewählt`,
        },
        on: { save: _vm.removeFromGroup },
        model: {
          value: _vm.isConfirmDialogOpen,
          callback: function ($$v) {
            _vm.isConfirmDialogOpen = $$v
          },
          expression: "isConfirmDialogOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }