<template>
  <v-sheet
    class="v-application v-application--is-ltr vuetify-wrapper"
    content-class="v-application v-application--is-ltr vuetify-wrapper"
  >
    <v-btn
      v-if="!isEditMode"
      color="primary"
      x-small
      @click.stop="isDrawerOpen = !isDrawerOpen"
      class="add-appointment"
    >
      <v-icon>mdi-plus</v-icon>
      Termin Anlegen
    </v-btn>
    <v-btn
      v-if="isEditMode"
      dark
      class="edit-appointment"
      @click.stop="isDrawerOpen = !isDrawerOpen"
    >
      <v-icon small>mdi-pencil</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="isDrawerOpen"
      width="680px"
      overlay-opacity="0.8"
      fixed
      temporary
      right
    >
      <v-form ref="form" v-model="valid">
        <v-container class="apt-manager" fluid>
          <v-row>
            <v-col cols="12" class="py-6">
              <h3 v-if="!isEditMode">Neuen Termin anlegen</h3>
              <h3 v-if="isEditMode">Termin bearbeiten</h3>
            </v-col>
          </v-row>

          <!-- Services Field  -->
          <v-row>
            <v-col cols="12">
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                label="mögliche Dienstleistungen*"
                v-model="formData.available_services"
                :items="serviceOptions"
                multiple
                :rules="[validateServiceSelect]"
                class="service-dropdown mt-4"
              ></v-select>
            </v-col>
          </v-row>

          <!-- Date Field -->
          <v-row>
            <v-col cols="12">
              <v-menu
                v-model="isDateMenuOpen"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                :disabled="appointment?.wf_state == 'invoice' || appointment?.wf_state == 'tempInvoice'"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateFormatted"
                    label="Datum*"
                    id="date"
                    v-on="on"
                    required
                    :rules="requiredRules"
                    :hint="holidays"
                    persistent-hint
                    autocomplete="off"
                    class="stick-left"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.date"
                  no-title
                  locale="de-de"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <!-- Billing Field -->
          <v-row>
            <v-col cols="12">
              <v-select
                label="Art der Abrechnung"
                class="billing"
                :items="billingOptions"
                v-model="formData.billing"
              />
            </v-col>
          </v-row>

          <v-row class="align-center">
            <v-col cols="12">
              <appointment-time-dialog
                :defaultTimeSnippet="defaultTimeSnippet"
                v-model="formData"
              >
              </appointment-time-dialog>
            </v-col>
          </v-row>

          <!-- Mediation-charges Field -->
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model.number="formData.mediation_charge"
                type="number"
                min="0"
                label="Vermittlungsaufschlag / Rabatt"
                hint="für Flying Pharmacist"
                persistent-hint
                suffix="€"
                class="stick-left"
              />
            </v-col>
          </v-row>

          <!-- Mediation-charges Field -->
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model.number="formData.mediation_charge_description"
                label="Vermittlungsaufschlag / Rabatt Erläuterung"
                hint="für Flying Pharmacist"
                persistent-hint
                class="stick-left"
              />
              </v-col>
          </v-row>



          <!-- Other-charges Field -->
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model.number="formData.other_costs"
                type="number"
                min="0"
                label="sonstige Kosten Honorarbestandteil"
                hint="für Freiberufler"
                suffix="€"
                persistent-hint
                class="stick-left"
              />
            </v-col>
          </v-row>

          <!-- Other-costs-explanation Field -->
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="formData.other_costs_explanation"
                label="Erläuterung"
                hint="zu 'sonstige Kosten Honorarbestandteil' für Freiberufler"
                persistent-hint
                class="stick-left"
              />
            </v-col>
          </v-row>

          <!-- Comments Field -->
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="formData.comment"
                hint="(bitte keine Einträge vornehmen)"
                label="Kommentar von der Apotheke"
                persistent-hint
                rows="2"
              />
            </v-col>
          </v-row>

          <!-- Internal comments Field -->
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="formData.internal_comment"
                hint="nur für interne Kommunikation"
                label="Interner Kommentar"
                persistent-hint
                rows="2"
              />
            </v-col>
          </v-row>

          <!-- FB Comment Field -->
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                rows="2"
                v-model="formData.comment_to_fb"
                label="Kommentar für Freiberufler"
                persistent-hint
              />
            </v-col>
          </v-row>

          <!-- Other Fields -->
          <v-row>
            <v-col cols="12">
              <v-checkbox
                id="nightshift"
                v-model="formData.nightshift"
                label="Nachtschicht?"
                hide-details
              />
              <v-checkbox
                v-model="formData.commited"
                label="feste Zusage?"
                hide-details
              />
              <v-checkbox
                :disabled="pharmacy.is_angel"
                v-model="formData.is_angel"
                label="Engel?"
                hide-details
              />
              <v-checkbox
                v-model="formData.hba"
                label="HBA zwingend erforderlich?"
                hide-details
              />
            </v-col>
          </v-row>

          <!-- Invoice Approval Field -->
          <v-row v-if="isEditMode">
            <v-col cols="12">
              <v-select
                label="Rechnungsfreigabe"
                :items="appointmentApprovalOptions"
                v-model="formData.user_state"
              />
            </v-col>
          </v-row>

          <!-- Awaiting comments section -->
          <v-row v-if="isEditMode && formData.awaiting_comments && Object.keys(formData.awaiting_comments).length > 0">
            <v-col cols="12">
              <h6>Kommentare zur Rechnungsfreigabe</h6>
              <div>
                <!-- Loop over awaiting_comments object to display label: value -->
                <div v-for="(row) in formData.awaiting_comments" :key="row.label">
                  <strong class="py-2">{{ row.label }}:</strong> {{ row.comment }}
                </div>
              </div>
            </v-col>
          </v-row>

          <!-- Action buttons -->
          <v-row>
            <v-col>
              <v-btn
                @click="onSaveButtonClicked"
                :disabled="!valid"
                class="save-appointment"
                id="submit-apt"
                color="primary"
              >
                Speichern
              </v-btn>
              <v-btn class="close-drawer" @click="isDrawerOpen = false"
                >Abbrechen</v-btn
              >
              <AppointmentsClashesDialog
                :clashes="clashes"
                :appointments="[formData]"
                @confirm="submitData"
                @abort="clashes = []"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-navigation-drawer>
  </v-sheet>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapMutations } from "vuex";
import AppointmentTimeDialog from "./AppointmentTimeDialog.vue";
import AppointmentsClashesDialog from "./AppointmentsClashesDialog.vue";
import { AppointmentManagerDefaults } from "../../constants";
const TIME_FORMAT = "HH:mm";
const DEFAULT_TIME_DETAILS = {
  additional_information: "",
  break_duration: 0,
  break_has_fixed_time: null,
  break_start_time: null,
  paid_break: false
}

export default {
  props: {
    appointment: Object,
    pharmacy: Object,
  },
  components: {
    AppointmentTimeDialog,
    AppointmentsClashesDialog
  },
  data() {
    return {
      isDrawerOpen: false,
      isDateMenuOpen: false,
      serviceOptions: AppointmentManagerDefaults.serviceOptions,
      billingOptions: AppointmentManagerDefaults.billingOptions,
      appointmentApprovalOptions: AppointmentManagerDefaults.appointmentApprovalOptions,
      clashes: [],
      holidays: "",
      formData: {
        begin: this.appointment ? moment.utc(this.appointment.begin).format(TIME_FORMAT) : "08:00",
        end: this.appointment ? moment.utc(this.appointment.end).format(TIME_FORMAT) : "18:00",
        date: this.appointment ? this.appointment.date : "",
        time: this.appointment ? this.appointment.time : "08.00 - 18.00 Uhr",
        available_services: this.appointment ? this.appointment.available_services : ["substitution"],
        billing: this.appointment ? this.appointment.billing : "hours",
        quantity: this.appointment ? this.appointment.quantity : 10,
        mediation_charge: this.appointment ? this.appointment.mediation_charge : 0,
        mediation_charge_description: this.appointment ? this.appointment.mediation_charge_description : "",
        other_costs: this.appointment ? this.appointment.other_costs : 0,
        other_costs_explanation: this.appointment ? this.appointment.other_costs_explanation : "",
        comment: this.appointment ? this.appointment.comment : "",
        internal_comment: this.appointment ? this.appointment.internal_comment : "",
        comment_to_fb: this.appointment ? this.appointment.comment_to_fb : "",
        nightshift: this.appointment ? this.appointment.nightshift : false,
        commited: this.appointment ? this.appointment.commited : false,
        hba: this.appointment ? this.appointment.hba : false,
        hidden: this.appointment ? this.appointment.hidden : false,
        time_details: this.appointment?.time_details ? {
          ...this.appointment.time_details,
          break_start_time: this.appointment.time_details.break_start_time && moment.utc(this.appointment.time_details.break_start_time).format(TIME_FORMAT),
        } : {},
        user_state: this.appointment ? this.appointment.user_state : "",
      },
      valid: true,
      requiredRules: [(v) => !!v || "Dieses Feld ist ein Pflichtfeld"],
      hoursRules: [(v) => (!!v && v > 0) || "Bitte die Stunden angeben"],
    }
  },
  computed: {
    isEditMode() {
      return !!this.appointment; // Determines the mode based on the presence of appointment
    },
    dateFormatted() {
      if (!this.formData.date) return null;
      return moment(this.formData.date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    defaultTimeSnippet() {
      const d = moment(this.formData.date, "YYYY-MM-DD");
      const selectedDay = d.day() == 0 ? 7 : d.day();
      return this.pharmacy["snippet" + selectedDay] ?? "";
    },
    date: {
      get() {
        return this.formData.date;
      },
      set(value) {
        this.formData.date = moment(value).format("YYYY-MM-DD");
      },
    },
    pharmacyURL() {
      return `/pharmacies/${this.pharmacy._id}`;
    },
    ActionURL() {
      let URLPATH = !this.isEditMode
        ? "/add_appointment"
        : "/" + this.appointment._id + "/edit";
      return `${this.pharmacyURL}${URLPATH}`;
    },
  },
  methods: {
    validateServiceSelect(value) {
      if (value && value.length) return true;
      return "Bitte wählen Sie mindestens eine Dienstleistungsart aus";
    },
    /** Only applicable to batch-add, not to batch-edit */
    async getDateClashesWithExistingAppointments() {
      const dates = [moment(this.formData.date).format("DD.MM.YYYY")];
      const clashesResponse = await axios.post(
        `${this.pharmacyURL}/check_batch_dates`,
        { dates, ids: [] }
      );
      if (clashesResponse.data.status == "wait") {
        return clashesResponse.data.clashes;
      }
      return null;
    },
    async onSaveButtonClicked() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.isEditMode) {
        this.clashes = await this.getDateClashesWithExistingAppointments();
        if (this.clashes) {
          return;
        }
      }
      this.submitData();
    },
    async submitData() {
      try {
        await axios.post(this.ActionURL, this.formData);
        this.isDrawerOpen = false;
        window.location.pathname = this.pharmacyURL;
      } catch (error) {
        this.alertError(
          `Termine konnten nicht gespeichert werden. Versuche es erneut oder kontaktiere einen Admin: ${error}`
        );
      }
    },
    formatHoliday(holiday) {
      if (holiday.type == "school") {
        return "Schulferien";
      } else if (holiday.special) {
        return `Sonderfeiertag: ${holiday.name}`;
      } else {
        return `Feiertag: ${holiday.name}`;
      }
    },
    formatHolidays(holidays) {
      return holidays.map((h) => this.formatHoliday(h)).join(", ");
    },

    getWeekDay(date) {
      return moment(date).day() || 7; // Map Sunday from 0 to 7 so that pharmacy['begin7']/end7 etc can be accessed
    },
    getPharmacyWeekdayDefaults(selector, date) {
      const weekday = this.getWeekDay(date);
      return this.pharmacy[`${selector}${weekday}`];
    },

    ...mapMutations({
      alertError: "alert/error",
    }),
  },

  watch: {
    date: async function (newVal) {
      if (this.isDrawerOpen) {
        const URL = `/holidays/${newVal}`;
        const response = await axios(`${this.pharmacyURL}${URL}`);
        const text = this.formatHolidays(response.data);
        this.holidays = text;
      }
    },
    isDateMenuOpen: function (newVal) {
      if (!this.isEditMode && newVal && !this.formData.date) {
        const today = moment().format("YYYY-MM-DD");
        this.$set(this.formData, "date", today);
      }
    },

    "formData.nightshift"(isNightShift) {
      if (isNightShift) {
        this.formData.billing = "flatrate";
        this.formData.begin = "20:00";
        this.formData.end = "07:00";
        this.formData.time = "ND 20.00 - 07.00 Uhr"
      } else {
        this.formData.billing = "hours";
        this.formData.begin = "08:00";
        this.formData.end = "18:00";
        this.formData.time = "08.00 - 18.00 Uhr"
      }
      this.formData.time_details = { ...DEFAULT_TIME_DETAILS }
    },
    "formData.date"(date) {
      const beginDate = this.getPharmacyWeekdayDefaults('begin', date) || '1900-01-01T08:00:00';
      const endDate = this.getPharmacyWeekdayDefaults('end', date) || '1900-01-01T18:00:00';
      const time = this.getPharmacyWeekdayDefaults('snippet', date) || "08.00 - 18.00 Uhr";
      const quantity = this.getPharmacyWeekdayDefaults('hours', date) || 10;
      this.formData.begin = moment.utc(beginDate).format(TIME_FORMAT);
      this.formData.end = moment.utc(endDate).format(TIME_FORMAT);
      this.formData.time = time;
      this.formData.quantity = quantity;
    },
    isDrawerOpen(newVal) {
      if (newVal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script>

<style scoped>
.add-appointment {
  padding: 8px 16px !important;
  min-height: 30px !important;
  height: 33px !important;
}

.edit-appointment {
  background: #5bc0de !important;
  min-height: 30px !important;
  height: 30px !important;
  min-width: 30px !important;
  padding: 0 10px !important;
}
.vuetify-wrapper .save-appointment {
  margin-right: 10px;
}
.time .vc-container .vc-time-picker .vc-date {
  display: none !important;
}
.apt-manager {
  padding: 48px;
}
.v-menu__content {
  width: auto !important;
  min-width: auto !important;
}
.vuetify-wrapper .v-navigation-drawer--temporary {
  z-index: 9999;
}

</style>
