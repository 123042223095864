var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-btn",
        {
          attrs: {
            loading: _vm.loading,
            "min-width": "30",
            small: "",
            outlined: "",
            text: "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggleDialog.apply(null, arguments)
            },
          },
        },
        [
          _c("v-icon", { attrs: { small: "", color: "black" } }, [
            _vm._v("mdi-currency-eur"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "content-class": "vuetify-wrapper" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v("\n                Preisvorschlag\n            "),
              ]),
              _vm._v(" "),
              _vm.loading
                ? _c("v-card-text", [
                    _vm._v(
                      "\n                Preisvorschlag wird berechnet...\n            "
                    ),
                  ])
                : !_vm.pricingInformation
                ? _c("v-card-text", [
                    _vm._v(
                      "\n                Fehler bei der Preisberechnung\n            "
                    ),
                  ])
                : _vm.isError
                ? _c("v-card-text", [
                    _vm._v(
                      "\n                Fehler bei der Preisberechnung: " +
                        _vm._s(_vm.errorMessage) +
                        "\n            "
                    ),
                  ])
                : _c("v-card-text", [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("th", [_vm._v("Service")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Apo")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("FB")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("FPP")]),
                        _vm._v(" "),
                        _c("th", [_vm._v("Erklärung")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.pricingInformation.configs,
                          function (config) {
                            return _c("tr", { class: config.service }, [
                              _c("td", [
                                _vm._v(_vm._s(_vm.$t(config.service))),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "apo" }, [
                                _vm._v(_vm._s(config.total.pharmacy) + "€"),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "fb" }, [
                                _vm._v(_vm._s(config.total.pharmacist) + "€"),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "fpp" }, [
                                _vm._v(_vm._s(config.margin) + "€"),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(config.explanation))]),
                            ])
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }