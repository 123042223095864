var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "v-application v-application--is-ltr",
        "max-width": "580px",
        fullscreen: !_vm.$vuetify.breakpoint.mdAndUp,
      },
      model: {
        value: _vm.isDialogOpen,
        callback: function ($$v) {
          _vm.isDialogOpen = $$v
        },
        expression: "isDialogOpen",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "overflow-auto", attrs: { id: "discountCard" } },
        [
          _c("v-card-title", [_vm._v(_vm._s(_vm.dialogTitle))]),
          _vm._v(" "),
          _c("v-card-text", [
            _c(
              "div",
              { staticClass: "discount-form" },
              [
                _c("v-text-field", {
                  attrs: {
                    id: "discountName",
                    value: _vm.value.discountName,
                    outlined: "",
                    label: "Rabatt Name",
                  },
                  on: {
                    input: (value) => _vm.onChange("discountName", value),
                    "update:value": function ($event) {
                      return _vm.$set(_vm.value, "discountName", $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("v-text-field", {
                  attrs: {
                    id: "description",
                    value: _vm.value.description,
                    outlined: "",
                    label: "Rabatt Beschreibung",
                  },
                  on: {
                    input: (value) => _vm.onChange("description", value),
                    "update:value": function ($event) {
                      return _vm.$set(_vm.value, "description", $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("v-select", {
                  staticClass: "discountType",
                  attrs: {
                    value: _vm.value.discountType,
                    outlined: "",
                    label: "Rabatt Typ",
                    items: _vm.discountTypeOptions,
                    variant: "outlined",
                  },
                  on: {
                    input: (value) => _vm.onChange("discountType", value),
                    "update:value": function ($event) {
                      return _vm.$set(_vm.value, "discountType", $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("v-text-field", {
                  attrs: {
                    id: "discountValue",
                    value: _vm.value.discountValue,
                    type: "number",
                    outlined: "",
                    label: "Rabatt Wert",
                    min: "0",
                  },
                  on: {
                    input: (value) => _vm.onChange("discountValue", value),
                    "update:value": function ($event) {
                      return _vm.$set(_vm.value, "discountValue", $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("v-select", {
                  staticClass: "flatRateDiscountType",
                  attrs: {
                    value: _vm.value.flatRateDiscountType,
                    outlined: "",
                    label: "ND Rabatt Typ",
                    items: _vm.discountTypeOptions,
                    variant: "outlined",
                  },
                  on: {
                    input: (value) =>
                      _vm.onChange("flatRateDiscountType", value),
                    "update:value": function ($event) {
                      return _vm.$set(_vm.value, "flatRateDiscountType", $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("v-text-field", {
                  attrs: {
                    id: "flatRateDiscountValue",
                    value: _vm.value.flatRateDiscountValue,
                    type: "number",
                    outlined: "",
                    label: "ND Rabatt Wert",
                    min: "0",
                  },
                  on: {
                    input: (value) =>
                      _vm.onChange("flatRateDiscountValue", value),
                    "update:value": function ($event) {
                      return _vm.$set(
                        _vm.value,
                        "flatRateDiscountValue",
                        $event
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("v-text-field", {
                  attrs: {
                    id: "startDate",
                    value: _vm.value.startDate,
                    type: "date",
                    outlined: "",
                    label: "Rabatt Start date",
                  },
                  on: {
                    input: (value) => _vm.onChange("startDate", value),
                    "update:value": function ($event) {
                      return _vm.$set(_vm.value, "startDate", $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("v-text-field", {
                  attrs: {
                    id: "endDate",
                    value: _vm.value.endDate,
                    type: "date",
                    outlined: "",
                    label: "Rabatt End date",
                  },
                  on: {
                    input: (value) => _vm.onChange("endDate", value),
                    "update:value": function ($event) {
                      return _vm.$set(_vm.value, "endDate", $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c("v-switch", {
                  attrs: { id: "isActive", label: "Ist der Rabatt aktiv?" },
                  model: {
                    value: _vm.value.isActive,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "isActive", $$v)
                    },
                    expression: "value.isActive",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "secondary" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("\n        Abbrechen\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "discount-btn",
                  attrs: {
                    color: "success",
                    disabled: _vm.disableDiscountCreation,
                    id: "createEditDiscount",
                    density: "comfortable",
                  },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("\n        " + _vm._s(_vm.ctaTitle) + "\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }