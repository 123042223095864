var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vuetify-wrapper v-application" }, [
    _c(
      "div",
      { staticClass: "discount-form-container elevation-5" },
      [
        _c("h2", { staticStyle: { "padding-bottom": "0.5rem" } }, [
          _vm._v("Rabatt"),
        ]),
        _vm._v(" "),
        _c(
          "v-btn",
          {
            attrs: { elevated: "", type: "button", id: "newDiscountBtn" },
            on: {
              click: function ($event) {
                _vm.isCreateDialogOpen = true
              },
            },
          },
          [
            _c("v-icon", [_vm._v("mdi-plus")]),
            _vm._v("\n      Neuer Rabatt\n    "),
          ],
          1
        ),
        _vm._v(" "),
        _c("discount-form-dialog", {
          attrs: {
            dialogTitle: "Erstellen Rabatt",
            ctaTitle: "Rabatt erstellen",
            ctaHandler: _vm.createDiscount,
            isDiscountFormDialogOpen: _vm.isCreateDialogOpen,
          },
          on: {
            "update:isDiscountFormDialogOpen": function ($event) {
              _vm.isCreateDialogOpen = $event
            },
            "update:is-discount-form-dialog-open": function ($event) {
              _vm.isCreateDialogOpen = $event
            },
            submit: _vm.createDiscount,
          },
          model: {
            value: _vm.discount,
            callback: function ($$v) {
              _vm.discount = $$v
            },
            expression: "discount",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "discount-form-list elevation-5" },
      [
        _vm.discounts?.length === 0
          ? _c("div", { staticClass: "no-discounts" }, [
              _vm._v("\n      Es können keine Rabatte angezeigt werden\n    "),
            ])
          : _c("v-simple-table", {
              staticClass: "discount-table",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("thead", [
                        _c("tr", { staticClass: "vuetify-wrapper" }, [
                          _c("th", [_vm._v("Rabatt Name")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Rabatt Beschreibung")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Rabatt Typ")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Rabatt Wert")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("ND Rabatt Typ")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("ND Rabatt Wert")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Rabatt Startdatum")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Rabatt Enddatum")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Rabatt ist aktiv?")]),
                          _vm._v(" "),
                          _c("th", { staticClass: "text-center" }, [
                            _vm._v("Aktionen"),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.discounts, function (item) {
                          return _c("discounts-table-row", {
                            attrs: { discountDetails: item },
                            on: { refresh: _vm.fetchAllDiscounts },
                          })
                        }),
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }