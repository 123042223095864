<template>
    <v-dialog
        v-model="isOpen"
        content-class="v-application v-application--is-ltr group-dialog"
        max-width="580px"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <template>
                    <v-container
                        class="px-0"
                        fluid
                    >
                        <v-radio-group v-model="mode">
                            <v-radio
                                label="Neue Termingruppe anlegen"
                                id="add_mode_0"
                                :value="0"
                            ></v-radio>
                            <v-radio
                                label="Zu bestehender Termingruppe hinzufügen"
                                id="add_mode_1"
                                :disabled="!groupFirstAppointments.length"
                                :value="1"
                            ></v-radio>
                        </v-radio-group>
                        <v-select
                            v-if="mode === 1"
                            v-model="groupId"
                            :items="groupOptions"
                            placeholder="Termingruppe"
                            class="select-group"
                        ></v-select>
                    </v-container>
                </template>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn
                    outlined
                    color="secondary"
                    @click="isOpen = false"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    depressed
                    color="primary"
                    class="save"
                    @click="save"
                    :disabled="mode === 1 && !groupId"
                >
                    speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
    export default {
        props: {
            value: Boolean,
            groupFirstAppointments: Array
        },
        data() {
            return {
                groupId: null,
                mode: 0
            }
        },
        methods: {
            async save() {
                this.$emit('add-group', {
                    mode: this.mode,
                    ...(this.groupId && { groupId: this.groupId })
                });
                this.isOpen = false
            }
        },
        computed: {
            isOpen: {
                get() {
                    return this.value
                },
                set(state) {
                    this.$emit('input', state)
                }
            },
            groupOptions() {
                return this.groupFirstAppointments.map(appointment => ({
                    text: `${appointment.date} (${appointment.group_size})`,
                    value: appointment.group_id
                }))
            }
        }
    }
</script>

<style scoped></style>