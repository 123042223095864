<template>
    <v-dialog
        v-model="isOpen"
        content-class="v-application v-application--is-ltr"
        max-width="580px"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <v-alert
                    dense
                    outlined
                    color="warning"
                    icon="mdi-alert-circle"
                >
                    {{ message }}
                </v-alert>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn
                    @click="isOpen = false"
                    color="secondary"
                    outlined
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    @click="save"
                    color="primary"
                    class="save"
                    depressed
                >
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
    export default {
        props: {
            value: Boolean,
            message: String
        },
        data: () => ({}),
        methods: {
            async save() {
                try {
                    this.$emit('save')
                    this.isOpen = false
                } catch (error) {
                    console.log(error)
                }
            }
        },
        computed: {
            isOpen: {
                get() {
                    return this.value
                },
                set(state) {
                    this.$emit('input', state)
                }
            },
        }
    }
</script>

<style scoped></style>