var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-btn",
        {
          staticClass: "fb-approval-button",
          class: { "bootstrap-button": _vm.isjinja2 },
          attrs: {
            disabled: _vm.parsedDisabled,
            "min-width": "40",
            outlined: "",
            text: "",
            small: "",
            icon: _vm.isjinja2 ? true : false,
            title: "Send Approval Reminder email",
          },
          on: {
            click: function ($event) {
              _vm.isApprovalReminderDialogOpen = true
            },
          },
        },
        [_c("v-icon", { attrs: { size: "20" } }, [_vm._v("mdi-mail")])],
        1
      ),
      _vm._v(" "),
      _c("ApprovalReminderDialog", {
        attrs: { pharmacistId: _vm.pharmacist_id },
        model: {
          value: _vm.isApprovalReminderDialogOpen,
          callback: function ($$v) {
            _vm.isApprovalReminderDialogOpen = $$v
          },
          expression: "isApprovalReminderDialogOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }