var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "v-application v-application--is-ltr group-dialog",
        "max-width": "580px",
        fullscreen: !_vm.$vuetify.breakpoint.mdAndUp,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title"),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              [
                _c(
                  "v-container",
                  { staticClass: "px-0", attrs: { fluid: "" } },
                  [
                    _c(
                      "v-radio-group",
                      {
                        model: {
                          value: _vm.mode,
                          callback: function ($$v) {
                            _vm.mode = $$v
                          },
                          expression: "mode",
                        },
                      },
                      [
                        _c("v-radio", {
                          attrs: {
                            label: "Neue Termingruppe anlegen",
                            id: "add_mode_0",
                            value: 0,
                          },
                        }),
                        _vm._v(" "),
                        _c("v-radio", {
                          attrs: {
                            label: "Zu bestehender Termingruppe hinzufügen",
                            id: "add_mode_1",
                            disabled: !_vm.groupFirstAppointments.length,
                            value: 1,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.mode === 1
                      ? _c("v-select", {
                          staticClass: "select-group",
                          attrs: {
                            items: _vm.groupOptions,
                            placeholder: "Termingruppe",
                          },
                          model: {
                            value: _vm.groupId,
                            callback: function ($$v) {
                              _vm.groupId = $$v
                            },
                            expression: "groupId",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                },
                [_vm._v("\n                Abbrechen\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "save",
                  attrs: {
                    depressed: "",
                    color: "primary",
                    disabled: _vm.mode === 1 && !_vm.groupId,
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("\n                speichern\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }