<template>
    <span class="vuetify-wrapper">
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    :disabled="disabled"
                    @click="toggle"
                    :loading="loading"
                    min-width="30"
                    small
                    :title="title"
                >
                    <v-icon v-if="isDirectProposalAllowed" small color="red">mdi-account-cowboy-hat</v-icon>
                    <v-icon v-else small color="green">mdi-account-cowboy-hat</v-icon>
                </v-btn>
            </template>
            <span>
                {{ title }}
            </span>
        </v-tooltip>
    </span>
</template>

<script>

import axios from 'axios';
import { parseBool } from '../../helpers.js'
import { mapMutations } from 'vuex'

export default {
    name: 'ToggleDirectProposalBtn',
    components: { },
    props: {
        appointmentid: String,
        isDirectProposalAllowed: Boolean,
        disabled: {
            default: false,
            type: Boolean,
        },
    },
    data: function() {
        return {
            loading: false,
        };
    },
    computed: {
        title() {
            return "Wenn aktiv (rot) sehen nur die Freiberufler den Termin, denen der Termin vorgeschlagen wurde (automatisch und manuell)"
        },
    },
    methods: {
        async toggle() {
            this.loading = true;
            try {
                const response = await axios.post(`/api/appointments/${this.appointmentid}/toggle_direct_proposal/${!this.isDirectProposalAllowed}`);
                if (response.data){
                    this.$emit("update", response.data);
                }
            }
            catch(error) {
                this.alertError();
                console.warn("! failed to toggle direct proposal.", error)
            }
            finally {
                this.loading = false;
            }
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    }
};
</script>



<style scoped>
.bootstrap-button {
    /** Make it look the same as the bootstrap buttons */
    border-radius: 3px;
    border-color: #ccc;
    width: 34px !important;
    height: 30px !important;
    color: #333 !important;
}

.red--text {
    color: red !important;
}

.green--text {
    color: green !important;
}
</style>
