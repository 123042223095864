<template>
    <span class="vuetify-wrapper">
        <v-btn
            :disabled="parsedDisabled"
            @click="isApprovalReminderDialogOpen = true"
            class="fb-approval-button"
            min-width="40"
            outlined
            text
            small
            :icon="isjinja2 ? true : false"
            :class="{'bootstrap-button': isjinja2}"
            title="Send Approval Reminder email"
        >
            <v-icon size="20">mdi-mail</v-icon>
        </v-btn>

        <ApprovalReminderDialog
            v-model="isApprovalReminderDialogOpen"
            :pharmacistId="pharmacist_id"
        />
    </span>


</template>


<script>
    import { parseBool } from '../../helpers.js'
    import ApprovalReminderDialog from './ApprovalReminderDialog.vue';

    export default {
        name: 'ApprovalPharmacistsDialogButton',
        components: { ApprovalReminderDialog },
        props: {
            pharmacist_id: String,
            isjinja2: {
                type: Boolean,
                default: false
            },
            disabled: {
                default: false,
                type: Boolean,
            },
        },
        data: () => ({
            isApprovalReminderDialogOpen: false,
        }),
        computed: {
            /** Required for jinja2 compatibility */
            parsedDisabled() {
                return parseBool(this.disabled);
            },
            /** Required for jinja2 compatibility */
            parsedHidden() {
                return parseBool(this.is_appointment_hidden);
            },
        },
    }
</script>

<style scoped>
    .fb-approval-button {
        padding: 0 !important;
    }
    .bootstrap-button {
        /** Make it look the same as the bootstrap buttons */
        border-radius: 3px;
        border-color: #ccc;
        width: 34px !important;
        height: 30px !important;
        color: #333 !important;
    }
</style>