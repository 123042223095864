var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AppointmentManager", {
    attrs: { appointment: _vm.appointment, pharmacy: _vm.pharmacy },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }