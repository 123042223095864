<template>
    <v-dialog v-model="isOpen" content-class="v-application v-application--is-ltr" max-width="580px"
        :fullscreen="!$vuetify.breakpoint.mdAndUp">
        <v-card>
            <v-card-title class="headline">Termine Absage?</v-card-title>
            <v-card-text>
                <v-textarea v-model="rejectionComment" label="Kommentar für die Absage" outlined dense rows="3"
                    auto-grow></v-textarea>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn outlined color="secondary" @click="isOpen = false">Abbrechen</v-btn>
                <v-btn depressed color="error" @click="confirmRejection" class="confirm-delete-btn">Absage</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    props: {
        value: Boolean,
    },
    data() {
        return {
            rejectionComment: '',
        };
    },
    methods: {
        confirmRejection() {
            this.$emit('confirm-rejection', this.rejectionComment)
            this.closeDialog();
        },
        closeDialog() {
            this.$emit('input', false);
            this.rejectionComment = '';
        },
    },
    computed: {
        isOpen: {
            get() {
                return this.value
            },
            set(state) {
                this.$emit('input', state)
                if (!state) {
                    this.rejectionComment = '';
                }
            }
        },
    }
}
</script>
